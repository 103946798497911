* {
  margin: 0;
  padding: 0;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}

.box {
  width: 82%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  border-radius: 20px;
  padding-bottom: 40px;
  max-width: 800px;
}

.subHeader {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 103px;
  border-radius: 20px 20px 0 0;
  padding-right: 20px;
}

.textBox {
  display: flex;
  align-items: center;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}

.subHeaderTitle {
  font-size: 35px;
  font-weight: 500;
  margin: 0;
}

.mainBox {}

.innerBox {
  margin: 20px 0px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #EFEFEF;
}

.guestDetailBox {
  margin: 0px;
  padding: 10px 0px;
  border-radius: 4px;
  border: none;
}

.alert {
  margin-bottom: 20px;
}

.alertBox {
  margin-bottom: 20px;
}

.mainInnerBox {
  /* display: flex; */
  padding-left: 0%;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  /* Adjust as needed */
}

.box__inputField {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  /* Adjust as needed */
  flex: 1;
  /* Allow the fields to take equal space */
}

.label {
  /* margin-bottom: 8px; */
  margin-left: 0px;
  /* Space between label and input box */
  font-size: 14px;
  /* Adjust as needed */
  color: #333;
  /* Adjust as needed */
}

.inputBox {
  border: 1px solid #EFEFEF;
  /* Border color */
  padding: 8px;
  /* Space inside the box */
  border-radius: 4px;
  /* Rounded corners */
  /* display: flex; */
  align-items: center;
  /* Center input field vertically */
  /* background-color: #f9f9f9; */
  /* Background color of the box */
  margin: 10px;
  margin-left: 0px;
  background: #F9F9FB;
}

.box__inputField input,
.box__inputField .inputBox input {
  width: 100%;
  border-bottom: 0;
  background: none;
  /* Make input fields full width */
}

.box__inputField input:focus {
  border-bottom: 0;
}

input[type="date"] {
  display: unset;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  /* Aligns the button to the right */
  padding: 25px 0px 0px 0px;
  gap: 15px;
  /* Optional: Add some padding if needed */
}

.button {
  padding: 15px 20px;
  /* Increases the size of the button */
  font-size: 20px;
  /* Increases the font size */
  background-color: #115A84;
  /* Changes the button color */
  color: white;
  /* Button text color */
  border: none;
  border-radius: 10px;
  /* Optional: Rounds the corners */
  cursor: pointer;
}
.backbutton {
  padding: 15px 20px;
  /* Increases the size of the button */
  font-size: 20px;
  /* Increases the font size */
  background-color: #ffffff;
  /* Changes the button color */
  color: #115A84;
  border-color: #115A84;
  border: 2px solid #115A84;
  /* Button text color */
  border-radius: 10px;
  /* Optional: Rounds the corners */
  cursor: pointer;
}

.errorContainer {
  border-radius: 4px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  font-size: 14px;
  margin: 16px 0;
}

.button:hover {
  background-color: #115A84;
  /* Darker color on hover */
}
.modalButton:hover{
  background-color: #115A84 !important;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin: 20px 10px 10px 0px;
}

.checkboxContainer input {
  margin-right: 10px;
}

.checkboxText {
  font-size: 15px;
  /* font-weight: bold; */
}

.passCounter {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.priceContainer {
  display: flex;
  align-items: center;
  margin: 0px 10px 0px 0px;
}

.counterContainer {
  display: flex;
  align-items: center;
  border: 1px solid #115A84;
  border-radius: 10px;
  overflow: hidden;
}


.counterButton {
  /* display: inline-block; */
  padding: 10px;
  font-size: 18px;
  color: #fff;
  background-color: #115A84;
  border: none;
  /* border-radius: 5px; */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  /* margin: 0 5px; */
  width: 40px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.counterButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.counterButton:hover:not(:disabled) {
  background-color: #115A84;
}

.counterButton:active:not(:disabled) {
  transform: scale(0.95);
}

.counter {
  /* font-size: 18px; */
  margin: 0 10px;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #ccc; */
  /* border-radius: 5px; */
  /* background-color: #fff; */
}

.vehicleCheckboxContainer {
  width: 50%;
}

.vehiclecheckboxText {
  font-size: 15px;
  margin-left: 8px;
  cursor: pointer;
  /* font-weight: bold; */
}

.vehicleLeftContainer {
  margin: 0px 30px 0px 10px;

}

.vehicleOptions {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 10px 0px;
}

.vehicleOptionslabel {
  margin: 5px 0px 5px 0px;
}

.vehicleOptionsInput {
  margin: 5px 5px 5px 0px;
  padding: 8px;
  border: 1px solid #DFE9EE;
  border-radius: 3px;
  background: #F9F9FB;
}

.vehicleOptionsInput:focus-visible {
  border: 1px solid #DFE9EE;
}

.horizontalLine {
  margin: 15px 0 10px 0;
  border-top: 1px solid #EFEFEF;
}

.passOptLbl {
  margin: 10px 10px 10px 0px;
  font-weight: normal;
  font-size: 16px;
}

.vehicleBox {
  display: flex;
  margin: 0px 0px 0px 0px;
}

.vehicleInfoTitle {
  margin: 10px 0px 40px 0px;
}

.passHeader h2 {
  font-size: 20px;
}

.errorMessage {
  margin: 10px 10px 10px 20px;
  /* font-weight: bold; */
  color: red;
  font-size: 15px;
}

.passForm {
  width: 100%;
  padding: 0px 0px;
}
.propertyDetail{

}
.propertyHeading{
  font-size: 15px;
  margin: 50px 0px 0px 0px;
}
.propertyAddress{
  /* margin: 15px 0px 15px 0px; */
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 16px;
}

.guestHeading{
  font-size: 15px;
  margin: 30px 0px 0px 0px;
}

.vehicleFieldsContainer{
  width: 100%;
}
.paidLbl{
  padding: 5px 30px;
  background: #cccccc;
  cursor: not-allowed;
}

.modalButton:disabled {
  background-color: #ccc; /* Greyed out */
  opacity: 0.6;
}
@media screen and (min-width: 1200px) {
  .vehicleFieldsContainer{
    width: 50%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {}

@media screen and (max-width: 767px) {
  .box {
    margin-top: 130px;
    width: 100%;
  }
  .subHeaderTitle {
    font-size: 25px;
    font-weight: 500;
  }

  .row {
    display: block;
  }

  .vehicleBox {
    display: block;
    margin: 0;
  }

  .vehicleCheckboxContainer {
    margin-bottom: 10px;
  }

  .passCounter {
    margin-top: 10px;
    justify-content: space-between;
  }

  .buttonBox {
    border: none;
    border-top: 1px solid #EFEFEF;
  }

  .buttonContainer {
    justify-content: center;
  }

  .button {
    width: 100%;
    max-width: 300px;
  }
  .vehicleInfoTitle {
    margin: 10px 0px 10px 0px;
    font-size: 12px;
  }
  .passHeader h2 {
    font-size: 16px;
  }
  .passForm {
    max-width: 90%;
  }
  
}